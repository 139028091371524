<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-expansion-panel :inputs="inputs" :reactiveVar="reactiveVar">
      <base-form
        :searchBtn="true"
        :resetBtn="true"
        :inputs="inputs"
        :reactiveVar="reactiveVar"
        :numberOfCols="3"
        @submit-form="getShipmentConfirmPackings"
      >
      </base-form>
    </base-expansion-panel>
    <base-table
      :headers="shukkaAnkenKakutei.headers"
      :items="filteredAnkens ?? shipmentConfirmPackings"
      :columnName="[
        'item.picking_complete_flag',
        'item.priority',
        'item.bikou',
        'item.shipment_pending_flag',
        'item.shipping_seq_number_group',
        'item.edit',
        'item.data-table-select',
        'item.total_weight',
        'item.total_quantity',
        'item.total_price',
      ]"
      :itemKey="itemKey"
      :itemClass="itemClass"
      ref="base-table"
      @row-selected="
        (selected) => {
          this.rowSelected = selected;
        }
      "
      multiSort
      showSelect
      minWidth="1400"
    >
      <template v-slot:[`item.picking_complete_flag`]="{ item }">
        <v-icon v-if="item.picking_complete_flag">mdi-check-outline</v-icon>
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <span class="wrap-text">{{ item.priority }}</span>
      </template>

      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox :value="isSelected" :key="item.scheduled_shipping_date" @input="select($event)">
        </v-simple-checkbox>
      </template>

      <template v-slot:[`item.total_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_weight != null" class="wrap-text">{{ item.total_weight.commaString(' kg') }}</span>
        </td>
      </template>
      <template v-slot:[`item.total_price`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_price != null" class="wrap-text">{{ item.total_price.commaString(' $') }}</span>
        </td>
      </template>

      <template v-slot:[`item.total_quantity`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_quantity != null">{{ item.total_quantity.commaString() }}</span>
        </td>
      </template>

      <template v-slot:[`item.bikou`]="{ item }">
        <v-textarea
          v-if="item.editing"
          filled
          hide-details="true"
          :value="item.bikou"
          @change="editShipmentConfirmPacking([{ packing_no: item.packing_no, new_bikou: $event }])"
        ></v-textarea>
        <div v-else-if="item.bikou" style="min-width: 100px">
          <span style="line-break: anywhere" v-for="ele in item.bikou.split('\n')" :key="ele">{{ ele }}<br /></span>
        </div>
      </template>

      <template v-slot:[`item.shipment_pending_flag`]="{ item }">
        <div v-if="!item.scheduled_shipping_date">
          <lazy>
            <v-checkbox
              v-if="item.editing"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.shipment_pending_flag"
              dense
              hide-details
              :key="item.shipment_pending_flag"
              @change="
                editShipmentConfirmPacking([
                  {
                    packing_no: item.packing_no,
                    new_shipment_pending_flag: $event,
                  },
                ])
              "
            ></v-checkbox>
            <v-checkbox
              v-else
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.shipment_pending_flag"
              dense
              hide-details
              disabled
            ></v-checkbox>
          </lazy>
        </div>
      </template>

      <template v-slot:[`item.shipping_seq_number_group`]="{ item }">
        <v-text-field
          v-if="item.editing"
          filled
          hide-details="true"
          :value="item.shipping_seq_number_group"
          type="number"
          min="0"
          @change="
            editShipmentConfirmPacking([
              {
                packing_no: item.packing_no,
                new_shipping_seq_number_group: $event,
              },
            ])
          "
        ></v-text-field>
        <span class="wrap-text" v-else>{{ item.shipping_seq_number_group }}</span>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <div v-if="role">
          <lazy>
            <v-btn v-if="item.editing" text icon color="green lighten-2" @click="saveEdit(item)">
              <v-icon large>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              icon
              color="blue lighten-2"
              @click="
                editShipmentConfirmPacking([
                  {
                    packing_no: item.packing_no,
                    editing: true,
                    new_bikou: item.bikou,
                    new_shipping_seq_number_group: item.shipping_seq_number_group,
                    new_shipment_pending_flag: item.shipment_pending_flag,
                  },
                ])
              "
            >
              <v-icon large>mdi-pencil-box</v-icon>
            </v-btn>
          </lazy>
          <v-btn
            v-if="item.editing"
            text
            icon
            color="red lighten-2"
            @click="
              editShipmentConfirmPacking([
                {
                  packing_no: item.packing_no,
                  editing: false,
                },
              ])
            "
          >
            <v-icon large>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </template>
    </base-table>
    <v-card-actions class="justify-center mb-3">
      <v-menu
        v-if="role"
        v-model="calendarScheduledShippingDateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="mx-2"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="success"
            v-on="on"
            v-bind="attrs"
            :disabled="hasPendingOrScheduled || rowSelected.length === 0"
            >{{ labels.BTN_SHUKKA_YOTEIBI_SETTEI }}</v-btn
          >
        </template>
        <v-date-picker ref="calendar_scheduled_shipping_date`" v-model="calendarScheduledShipping" no-title>
          <v-btn
            text
            color="error"
            @click="
              calendarScheduledShipping = null;
              calendarScheduledShippingDateMenu = false;
            "
            >キャンセル</v-btn
          >
          <v-btn text color="success" @click="handleShipmentConfirm">保存</v-btn>
        </v-date-picker>
      </v-menu>
      <csv-result-dl :disabled="shipmentConfirmPackings.length === 0" :row-selected="rowSelected"></csv-result-dl>
    </v-card-actions>
    <v-dialog v-model="showModal" max-width="800px" @click:outside="handleClose">
      <v-card color="white">
        <v-card-title class="text-subtitile-2 orange darken-1 white--text py-1">
          電子画像が存在しない案件があります
        </v-card-title>

        <v-card-text class="pa-1 pl-3">下記案件に電池画像が存在していません。</v-card-text>
        <ul v-for="{ invoice_no, devices } in invoicesWithNoBatteryImage" :key="invoice_no">
          <v-card-text class="pt-1 pb-0 pl-1 font-weight-medium">{{ invoice_no }}</v-card-text>
          <v-card-text class="pt-1 pb-2 pl-3">{{ devices }}</v-card-text>
        </ul>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text :loading="loading" :disabled="loading" @click="saveDate"> OK </v-btn>

          <v-btn color="warning" text :disabled="loading" @click="handleClose"> キャンセル </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { inputs } from '../consts';

export default {
  name: 'ShukkaAnkenKakutei',
  components: {
    lazy: () => import(/* webpackChunkName: "Lazy" */ '@/components/ui/Lazy.vue'),
  },
  data() {
    return {
      itemKey: 'packing_no',
      calendarScheduledShippingDateMenu: false,
      calendarScheduledShipping: null,
      rowSelected: [],
      role: false,
      reactiveVar: {
        invoiceDate: [],
        ankenNo: '',
        buyerName: '',
        nyukinDate: [],
        shukkayoteiDate: [],
        status: '',
        progress: '',
        minyukinshukka: false,
        yoteibiminyuryoku: false,
      },
      showModal: false,
      filteredAnkens: null,
    };
  },
  computed: {
    ...mapGetters({
      shukkaAnkenKakutei: 'defined/shukkaAnkenKakutei',
      shipmentConfirmPackings: 'shukkaAnkenKakutei/shipmentConfirmPackings',
      commonSearchConditions: 'common/searchConditions',
      labels: 'defined/labels',
      statusItems: 'defined/statusItems',
      path: 'defined/pathNames',
      user: 'defined/user',
      loading: 'ui/loading',
      currentBases: 'base/currentBases',
    }),
    hasPendingOrScheduled() {
      if (!this.rowSelected) {
        return false;
      }
      for (const row of this.rowSelected) {
        if (!row.new_shipment_pending_flag && (row.shipment_pending_flag || row.scheduled_shipping_date)) {
          return true;
        }
      }
      return false;
    },
    searchConditions() {
      return this.commonSearchConditions?.shukkaAnkenKakutei;
    },
    progress() {
      return this.commonSearchConditions?.shukkaAnkenKakutei?.progress ?? null;
    },
    inputs() {
      return inputs.shukkaAnkenKakutei(this.statusItems);
    },
    calendarScheduledShippingText() {
      return this.calendarScheduledShipping ? moment(this.calendarScheduledShipping).format('YYYYMMDD') : '';
    },
    invoicesWithNoBatteryImage() {
      const result = [];
      for (const row of this.rowSelected) {
        if (row.no_battery_img_models?.length > 0) {
          result.push({
            invoice_no: row.matter_no,
            devices: row.no_battery_img_models.join('、'),
          });
        }
      }
      return result;
    },
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.SHUKKA_ANKEN_KAKUTEI,
      authority: this.user.authority,
    });
    this.reactiveVar = this.searchConditions;
  },
  methods: {
    ...mapActions({
      getShipmentConfirmPackings: 'api/getShipmentConfirmPackings',
      editShipmentConfirmPacking: 'shukkaAnkenKakutei/editShipmentConfirmPacking',
      updateShipmentConfirmPackings: 'api/updateShipmentConfirmPackings',
      updateScheduledShippingDate: 'api/updateScheduledShippingDate',
      getRole: 'common/getRole',
    }),
    itemClass(item) {
      if (item.editing) {
        return 'highlight-green not-change-hover-color';
      }
      return '';
    },
    handleShipmentConfirm() {
      const hasNoBatteryImageInvoices =
        this.rowSelected.filter(({ no_battery_img_models }) => no_battery_img_models?.length > 0)?.length > 0;

      if (hasNoBatteryImageInvoices) {
        this.showModal = hasNoBatteryImageInvoices;
      } else this.saveDate();
    },
    async saveDate() {
      this.handleClose();
      const packingNos = [];
      for (const row of this.rowSelected) {
        if (!row.shipment_pending_flag && !row.scheduled_shipping_date && !row.new_shipment_pending_flag) {
          packingNos.push(row.packing_no);
        }
      }
      await this.updateScheduledShippingDate({
        packingNos,
        scheduledShippingDate: this.calendarScheduledShippingText,
      });
      this.rowSelected = [];
      await this.getShipmentConfirmPackings(this.searchConditions);
      this.$refs['base-table'].$refs['table'].toggleSelectAll();
      this.$refs['base-table'].itemSelected.length = 0;
    },
    async saveEdit(item) {
      await this.updateShipmentConfirmPackings([
        {
          packingNo: item.packing_no,
          bikou: item.new_bikou,
          shippingSeqNumberGroup: item.new_shipping_seq_number_group,
          shipmentPendingFlag: item.new_shipment_pending_flag,
        },
      ]);
      await this.getShipmentConfirmPackings(this.searchConditions);
    },
    handleClose() {
      this.showModal = false;
      this.calendarScheduledShippingDateMenu = false;
    },
    ankenFilter() {
      this.filteredAnkens = this.shipmentConfirmPackings.filter((item) => {
        const baseIds = this.currentBases.map((base) => base.id);
        const res = baseIds.includes(item.base_id);
        return res;
      });
    },
  },
  watch: {
    searchConditions(newVal) {
      this.reactiveVar = newVal;
    },
    currentBases() {
      this.filteredAnkens = this.shipmentConfirmPackings.filter((item) => {
        const baseIds = this.currentBases.map((base) => base.id);
        const res = baseIds.includes(item.base_id);
        return res;
      });
    },
    shipmentConfirmPackings(shipmentConfirmPackings) {
      if (this.currentBases.length > 0) {
        this.ankenFilter();
      } else {
        this.filteredAnkens = shipmentConfirmPackings;
      }
    },
  },
};
</script>
<style>
.wrap-text {
  white-space: pre;
}
</style>
